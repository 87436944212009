/**
 * Music component
 * shows what you are listening to currently from last.fm
 */

import React, { useState, useEffect } from "react"

const LAST_FM_ENDPOINT = "https://ws.audioscrobbler.com/2.0/"

const Music = ({ username }) => {
  const [recentTracks, setRecentTracks] = useState([])
  useEffect(() => {
    // get data from last.fm
    // https://www.last.fm/api/show/user.getRecentTracks
    const params = {
      method: "user.getrecenttracks",
      user: username,
      api_key: "f8c53bdd0474b43372029dbb440eb5b8",
      format: "json",
    }
    const queryString = Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&")
    try {
      fetch(`${LAST_FM_ENDPOINT}?${queryString}`)
        .then(response => response.json())
        .then(resultData => {
          if (resultData?.recenttracks?.track) {
            setRecentTracks(resultData?.recenttracks?.track)
          }
        })
    } catch (error) {
      console.log("error", error)
    }
  }, [username])

  const nowPlaying =
    recentTracks && recentTracks.length
      ? recentTracks[0]["@attr"]?.nowplaying
      : false

  return (
    <>
      {nowPlaying && (
        <span className="music">
          <span>Currently listening to </span>
          <a
            className="music__link"
            href={`https://www.last.fm/user/${username}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="music__title">{recentTracks[0].name}</span> -{" "}
            <span className="music__artist">
              {recentTracks[0].artist["#text"]}
            </span>
          </a>
          .
        </span>
      )}
    </>
  )
}

export default Music
